/* row Loading */

.row {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -24px 0 #FF3D00, 24px 0 #FFF;
  }
  66% {
    background: #FF3D00;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }
  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FF3D00;
  }
}

/* round Loading */

.round {
  width: 48px;
  height: 48px;
  display: block;
  margin: 0 auto;
  position: relative;
  border: 3px solid;
  border-color:#de3500 #0000 #fff #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}
.round:before , .round:after{
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color:#fff;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.round:after {
  border-color: #de3500 #0000 #0000 #0000 ;
  transform: translate(32px, 3px) rotate(-35deg);
}
@keyframes rotate {
  100%{ transform: rotate(360deg)}
}


.fullPage {
  width: 100%;
  position: fixed;
  z-index: 999;
  height: 100vh;
  background-color: #c9cdd3;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .fullPage {
    height: calc(100vh + 140px);
    margin-top: -140px;
  }
}


.fullPage .loader2 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
}

.fullPage .loader2::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #FF3D00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}

.fullPage .loader {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.fullPage .loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border-left: 4px solid #FF3D00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

/* roundSmall */
.roundSmall {
  width: 25px;
  height: 25px;
  border: 2.5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: roundSmallRotation 1s linear infinite;
}

@keyframes roundSmallRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 