.bugreg {
    width: 55px;
    height: 35px;
    background-color: var(--baseOrange);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    padding: 0px 5px;
    cursor: pointer;
    position: absolute;
    right: 20px;
}

.closeIcon {
    width: 55px;
    height: 35px;
    cursor: pointer;
    position: absolute;
    right: 20px;
}

.closeIcon svg {
    width: 55px;
    height: 35px;
}

.row {
    display: block;
    height: 5px;
    background: #FFFFFF;
    width: 100%;
}

.sideBar {
    height: 100vh;
    width: 100%;
    padding: 30px 20px 100px 20px;
    background-color: #003B56;
    position: fixed;
    z-index: 998;
    transition: .3s;
    left: 0;
    top: 0;
    display: flex;
    gap: 20px;
    flex-direction: column;
    animation-name: fromleft;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    transition: .3s;
    overflow-y: auto;
    padding-top: 175px;
}

/* .sideBar::-webkit-scrollbar {
    width: 8px;
}
  
.sideBar::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
  
.sideBar::-webkit-scrollbar-thumb {
    background-color: var(--baseOrange);
    border-radius: 10px;
} */

.closeBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.closeBtn svg {
    color: #fdfdff;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    width: 24px;
    height: 24px;
    fill: #FFFFFF;
    cursor: pointer;
}

.logo {
    width: auto;
    height: fit-content;
}

.linkWrapper:has(a) {
    border-bottom: 1px solid rgba(245, 128, 32, 0.4);
    padding-bottom: 15px;
}

.linkGroup {
    /* border-bottom: 1px solid rgba(245, 128, 32, 0.4); */
}

.seccondLinkWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.seccondLinkWrapper a {
    border-bottom: 1px solid rgba(245, 128, 32, 0.4);
    padding-bottom: 15px;
    color: #FDFDFF;
    font-weight: 400;
    font-size: 20px; 
    cursor: pointer;
    padding-bottom: 15px;
}

.linkGroup {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
}

.linkWrapper p,
.linkWrapper a {
    color: #FDFDFF;
    font-weight: 400;
    font-size: 20px; 
    cursor: pointer;
}

.linkGroupLable {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.nestedLinks {
    display: none;
    cursor: default;
}

.activeLinkGroup .nestedLinks {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
    padding-left: 50px;
    border-top: 1px solid rgba(245, 128, 32, 0.4);
    padding-top: 10px;
} 

.activeLinkGroup .nestedLinks:has(a) {
    border-top: 1px solid rgba(245, 128, 32, 0.4);
}

.nestedLinks a {
    display: inline;
    width: max-content;
    position: relative;
}

.nestedLinks a::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 7px;
    height: 7px;
    background-color: #FFFFFF;
    border-radius: 15px;
}

@media screen and (max-width: 768px) {
    .linkWrapper p,
    .linkWrapper a {
        width: 100%;
        display: block;
    } 
}

@keyframes fromleft {
    from {
      left: -375px;
    }
    to {
      left: 0px;
    }
}