.checkMark {
    height: 20px;
    width: 20px;
    background-color: #FDFDFF;
    border: 2px solid #F58020;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms;
    cursor: pointer;
}

.active {
    background-color: #F58020;
}