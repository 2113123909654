.wrapperSearch {
    display: flex;
    align-items: center;
    overflow-x: clip;
    position: relative;
}

.seaech {
    background-color: var(--baseOrange);
    width: 68px;
    height: 38px;
    border-radius: 0 5px 0px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 0;
}

.wrapperSearch:has(.dropDownClose) .seaech {
    border-radius: 0 5px 5px 0;
}

.input {
    max-width: 220px;
    display: flex;
    flex-direction: column;
}

.input input {
    border-radius: 5px 0 0px 0px !important;
}

.input:has(.dropDownClose) input {
    border-radius: 5px 0 0px 5px !important;
}

.dropDown {
    max-width: 290px;
    height: 250px;
    overflow-y: auto;
    overflow-x: clip;
    position: absolute;
    top: 38px;
    padding: 10px 5px;
    background: #FFFFFF;
    border-radius: 0 0 10px 10px;
    z-index: 9999;
    transition: all .2s;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dropDown a {
    transition: all .1s;
    padding: 0px 15px;
    font-weight: 700;
    font-size: 14px; 
    color: #003B56;
}

.dropDown a:hover {
    background: rgba(0, 59, 86, 0.219);
}

.dropDown::-webkit-scrollbar {
    width: 5px;
}
  
.dropDown::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
  
.dropDown::-webkit-scrollbar-thumb {
    background-color: #F58020;
    border-radius: 10px;
}
.close .dropDown {
    opacity: 0;
}

.dropDownEmpty {
    padding: 10px 20px;
    width: 100%;
    height: initial;
}

.dropDownClose {
    height: 0;
    opacity: 0;
}

.dropDownEmpty p {
    font-weight: 700;
    font-size: 14px; 
    color: #003B56;
}

.close {
    margin-right: -220px;
    animation-name: toRight;
    animation-duration: .3s;
    animation-timing-function: initial;
    transition: .3s;
    opacity: 0;
}

.open {
    animation-name: fromRight;
    animation-duration: .3s;
    animation-timing-function: initial;
    transition: .3s;
}

@keyframes fromRight {
    from {
        margin-right: -220px;
    }
    to {
        margin-right: 0px;
    }
}

@keyframes toRight {
    from {
        margin-right: 0px;
    }
    to {
        margin-right: -220px;
    }
}

@media screen and (max-width: 1140px) {
    .dropDown {
        padding: 10px 10px;
    }
    .wrapperSearch {
        max-width: 180px;
        margin-left: 10px;
    }
    .close {
        margin-right: -200px;
    }

}

@media screen and (max-width: 1024px) {
    .seaech {
        width: 55px;
        height: 35px;
    }
}

@media screen and (max-width: 768px) {
    .seaech {
        width: 72px;
        height: 39px;
    }

    .input {
        width: 100%;
        max-width: initial;
    }

    .input input {
        padding: 7.2px 20px !important;
    }

    .close {
        margin-right: -50px;
        max-width: initial;
    }
    .wrapperSearch {
        width: 100%;
        max-width: 100%;
        margin-left: initial;
    }

    .dropDown {
        max-width: initial;
        width: 100%;
        border-radius: 0 0 0 0px;
    }

    .dropDown a {
        padding: 7px 0px;
    }


}