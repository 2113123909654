.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
  
  .portal {
    background: rgba(0, 59, 86, 0.85);
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 23px;
    right: 28px;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  
  .content {
    overflow-y: auto;
    border-radius: 15px;
    border: 5px solid #F58020;
  }
  
  /* Custom Scrollbar */
  .content::-webkit-scrollbar {
    width: 0px;
  }
  
  .content::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .content::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 8px;
  }
  