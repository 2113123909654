.dropdown {
    position: relative;
    display: inline-block;
}
  
.dropdownBtn {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #003B56; 
    font-weight: 700;
}


.dropdownBtn::before {
    content: '';
    background-color: rgba(255, 255, 255, 0.336);
    border-radius: 20px;
    padding: 15px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 20px);
    height: 100%;
    transform: translate(-50%, -50%) scale(0);
    transition: all .1s;
}

@keyframes animatActive {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

.dropdownBtn:active::before {
    animation: animatActive .1s forwards;
} 

.dropdownMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;
    background-color: #fff;
    padding: 0.5rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    z-index: 10;
    border-radius: 10px;
}

.dropdownMenuItem {
    padding: 0.5rem;
    margin: 0;
}

.dropdownMenuItem a {
    text-decoration: none;
    color: #003B56;
    display: block;
}

.dropdownMenuItem a:hover {
    color: #F58020 !important;
}
  
.colorWhite {
    color: #FDFDFF !important;
    letter-spacing: 1.2px;
    font-weight: 600;
    font-family: sans-serif;
    transition: all .3s;
}

.colorWhite:hover {
    color: #F58020;
}

@media screen and (max-width: 1024px) {
    .dropdownBtn {
        font-size: 14px;
    }
}