.informationBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
}

.menuSelects {
    display: flex;
    align-items: center;
    gap: 50px;
}

.menuSelects a {
    cursor: pointer;
    color: #003B56; 
    font-weight: 700;
    font-size: 14px;
    transition: all .2s;
}

.menuSelects a:hover {
    color: #F58020;
}

.seaech {
    background-color: var(--baseOrange);
    width: 68px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorWhite {
    color: #FDFDFF !important;
    letter-spacing: 1.2px;
    font-weight: 600;
    font-family: sans-serif;
    transition: all .3s;
}

.colorWhite:hover {
    color: #F58020 !important;
}

@media screen and (max-width: 1024px) {
    .logo {
        max-width: 100px;
    }

    .menuSelects {
        font-size: 14px;
        gap: 15px;
    }

    .seaech {
        width: 55px;
        height: 35px;
    }
}
