.dropdown {
    position: relative;
    display: inline-block;
}

.wrapperDropdownInput {
    position: relative;
}

.dropdownInput {
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 4px;
    width: 183px;
    position: relative;
    padding: 7px 0 7px 20px;
    background: #FDFDFF;
    border-radius: 5px;
    max-width: 183px;
    transition: all .2s;
}

.dropdownInputIsOpen {
    border-radius: 5px 5px 0 0;
}

.dropdownInput:focus {
    outline: 2px solid transparent;
}

.wrapperIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 48px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F2F2F2;
    border-radius: 0px 5px 10px 0px;
    cursor: pointer;
    transition: all .2s;
}

.wrapperIconIsOpen {
    border-radius: 0px 5px 0px 0px;
}

.dropdownMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;
    background-color: #FFFFFF;
    padding: 0.5rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    width: 183px;
    z-index: 10;
    border-radius: 0 0 10px 10px;
}

.dropdownMenuItem {
    padding: 0.5rem;
    margin: 0;
    cursor: pointer;
    color: #003B56;
}

.dropdownMenuItem:hover {
    background: #F2F2F2;
    color: #F58020 !important;
}

.dropdownMenuNoItem {
    cursor: default;
}

.dropdownMenuItem a {
    text-decoration: none;
    color: #003B56;
    display: block;
}

.dropdownMenuItem a:hover {
    color: #F58020 !important;
}
  

@media screen and (max-width: 500px) {
    .dropdownInput {
        max-width: initial;
        width: 100%;
    }
    .dropdownMenu {
        width: 100%;
    }
}