.root {
    position: fixed;
    height: 100vh;
    background: #FFFFFF;
    width: 100%;
    z-index: 999;
    padding-top: 15px;
    overflow-y: auto;
    padding: 0px 10px;
    padding-bottom: 100px;
    padding-top: 15px;
}