.footer {
    width: 100%;
    padding: 30px 0;
    background: #F4F4F3;
}

.footerContent {
    display: flex;
    justify-content: space-between;
    gap: 85px;
}

.footerContentMobile {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.wrapperLogoImg {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footerContentMobile .wrapperLogoImg {
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(0, 59, 86, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
}

.footerContentMobile .linkGroup, 
.footerContentMobile .linkGroup ul {
    width: 100%;
    align-items: center;
}

.logo {
    width: auto;
    height: fit-content;
}

.social {
    display: none;
    gap: 15px;
    margin-top: 15px;
}

.underLine {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 2px solid rgba(0, 59, 86, 0.4);
    margin-top: 15px;
    padding-top: 25px;
}

.linkGroup, 
.linkGroup ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.linkGroup {
    transform: translateY(20px);
}

.seccondGroupCity{
    transform: translateY(50px);
}

.contactInfo {
    transform: translateY(0);
}

.groupName {
    color: #003B56;
    font-weight: 500;
    font-size: 20px; 
    white-space: nowrap;
}

.link {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: rgba(0, 59, 86, 0.8);
    font-size: 14px; 
    max-width: 215px;
    transition: all .2s;
}

.link:hover {
    color: #F58020;
}

.link .mail span,
.link p a {
    text-decoration: underline;
    color: #F58020;
}


.footerContentMobile .link {
    text-align: center;
}

.autoTransportText {
    color: #003B56; 
    font-size: 14px;
    text-align: center;
    margin-right: 5px;
}

.termsBlock {
    display: flex;
    align-items: center;
    gap: 5px;
}

.termsBlock a { 
    font-size: 14px;
    text-decoration: underline;
    color: #F58020;
}

.verticalRow {
    outline: 1px solid rgba(0, 59, 86, 0.4);
    transform: rotate(90deg);
    width: 20px;
    margin: 0px -10px;
}

.footerContentMobile .social {
    display: flex;
}

@media screen and (max-width: 1024px) {
    .underLine {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .footerContent {
        gap: 5px;
    }
    .groupName {
        font-size: 18px;
    }
    .link {
        font-size: 12.5px;
    }

    .logo {
        max-width: 115px;
        min-height: 80px;
    }
}