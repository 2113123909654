
.routeHistory {
    display: flex;
    gap: 6px;
    max-width: 380px;
    margin: 0 auto 40px auto;
    justify-content: center;
    overflow-x: auto;
}

.routeHistory::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }

.step {
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 1 1 auto;
}

.stepId {
    width: 80px;
    height: 48px;
    background-color: #003B56;
    border-radius: 25px;
    font-weight: 700;
    font-size: 24px; 
    color: #FDFDFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stepText {
    border-radius: 15px;
    color: #FFFFFF;
    font-weight: 700;
    white-space: nowrap;
    font-size: 16px;
}

.active {
    background-color: var(--baseOrange);
    color: #FDFDFF;
    padding: 0px 8px;
}

.row {
    height: 1px;
    background: #003B56;
    position: relative;
    flex: 1 1 auto;
    min-width: 15px;
}

.row::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: #003B56;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

.step {
    gap: 2px;
}

.stepId {
    max-width: 50px;
    height: 35px;
}

.active {
    padding: 0px 6px;
}

@media screen and (max-width: 768px) {
    .stepText {
        color: #003B56;
    }

    .stepText.active {
        color: #FFFFFF;
    }
}

@media screen and (max-width: 450px) {
    .routeHistory {
        max-width: 380px;
        justify-content: initial;
        padding-left: 10px;
    }
}