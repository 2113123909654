.header {
    max-height: 95px;
    width: 100%;
    padding: 0 0 0px 0;
    position: fixed;
    top: -200px;
    transition: .3s;
    z-index: 999;
    background: rgb(244, 244, 243);
}

.getQuote {
    background-color: #f4f7fb;
    padding: 10px 0;
}

.getQuote p {
    color: #F58020;
    font-weight: 700;
    font-size: 20px; 
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    cursor: pointer;
    max-width: 250px;
    margin: 0 auto;
}

.getQuote, 
.getQuote svg {
    transition: all .3s;
}

.informationBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 95px;
    position: fixed;
    background: #f4f7fb;
}

.menuSelects {
    display: flex;
    align-items: center;
    gap: 80px;
    width: 100%;
}

.seaech {
    background-color: var(--baseOrange);
    width: 68px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorWhite * {
    color: #003B56 !important;
    letter-spacing: 1.2px;
    font-weight: 600;
    font-family: sans-serif;
    transition: all .3s;
}

@media screen and (max-width: 1024px) {
    .logo {
        max-width: 100px;
    }

    .menuSelects {
        font-size: 14px;
        gap: 30px;
    }

    .seaech {
        width: 55px;
        height: 35px;
    }
}

@media screen and (max-width: 768px) {
    .header {
        min-height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        background-color: initial;
        top: initial;
    }

    .header:has(.informationBar) {
        min-height: 130px;
        padding-bottom: 10px;
    }
    
    .logo img {
        max-width: 90px;
    }

    .getQuote {
        position: fixed;
        width: 100%;
        z-index: 9999;
        top: 0;
    }

    .informationBar {
        margin-top: 50px;
        padding-bottom: 5px;
    }

    .header-fixed {
        top: 0;
        position: fixed;
        background-color: #f4f7fb;
    }
}

@keyframes fromTop {
    from {
      top: -100px;
    }
    to {
      top: 0;
    }
}