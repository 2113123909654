@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --blackIntoBlue: #003B56;
  --blackIntoBlueOpc: rgba(0, 59, 86, 0.8);
  --baseOrange: #F58020;
  --darkBlue: "#003B56";
  --ubuntu: 'Ubuntu', sans-serif;
  --arial: Arial, sans-serif;
  --poppins: 'Poppins', sans-serif;
  --white: "#FDFDFF";
  --tooltip-text-color: rgba(0, 59, 86, 0.8);
  --tooltip-background-color: #FDFDFF;
  --tooltip-margin: 15px;
  --tooltip-arrow-size: 6px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

section {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: clip;
}

.relatedPosts .react-reveal {
  width: 100%;
}

.css-j7o63n,
.css-v7esy,
.css-1wc848c-MuiFormHelperText-root.Mui-error,
.css-1d1r5q-MuiFormHelperText-root.Mui-error {
  position: absolute;
  bottom: -25px;
  left: 0;
}

.autocomplete {
  border-radius: 5px;
  background: #FDFDFF;
}

.autocomplete * {
  max-height: 44px;
}

html:has(.sideBar) {
  overflow-y: clip;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: none;
}

.css-18nc3u2 .MuiOutlinedInput-root,
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 9px !important;
}

.MuiAutocomplete-popper {
  z-index: 9999 !important;
}

.css-1wc848c-MuiFormHelperText-root {
  margin-left: 2px !important;
}

.css-i44wyl:has(#outlined-multiline-static):not(:has(.Mui-focused)):not(:has(.MuiInputLabel-shrink)),
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:has(#outlined-multiline-static):not(:has(.Mui-focused)):not(:has(.MuiInputLabel-shrink)) {
  border-radius: 5px;
}
.css-i44wyl:has(#outlined-multiline-static) {
  border-radius: 5px;
}

.css-i44wyl:has(#outlined-multiline-static):has(.Mui-focused) {
  border-radius: initial;
}

.css-i44wyl:has(#outlined-multiline-static):has(.MuiInputLabel-shrink) {
  border-radius: initial;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:has(#outlined-multiline-static) {
  border-radius: 5px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:has(#outlined-multiline-static):has(.Mui-focused) {
  border-radius: initial;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:has(#outlined-multiline-static):has(.MuiInputLabel-shrink) {
  border-radius: initial;
}

.css-1hof3tc,
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 17.5px 14px !important;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid rgba(0, 59, 86, 0.6) !important;
}

.css-18nc3u2 .MuiOutlinedInput-root .MuiAutocomplete-input,
.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7.5px 50px 7.5px 5px !important;
}