.scrollTopIcon {
    position: fixed;
    bottom: 30px;
    right: 40px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    overflow: hidden;
    background-color: #F58020!important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: toView;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    transition: .3s;
    z-index: 1;
    cursor: pointer;
}

.scrollTopIcon span {
    color: #fff;
    font-size: 18px;
    transition: .3s;
}

.toTop:hover span {
    animation: move-down 1s infinite;
}

.toBottom:hover span {
    animation: move-top 1s infinite;
}

.toTop {
    transform: rotate(0deg)
}

.toBottom {
    transform: rotate(180deg)
}

@keyframes move-down {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes move-top {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-5px);
  }
}

@keyframes toView {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}